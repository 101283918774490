import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Header from '../../component/Header/Header';
import { userRoles } from '../../utils';
import Footer from '../../component/Footer/Footer';

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    backgroundColor: '#fff',
    borderRadius: '0 10px 10px 10px',
    boxShadow: '0 3px 6px rgba(0,0,0,.16)',
    padding: theme.spacing(3.5),
    position: 'relative',
    width: '1150px',
    margin: '0 auto',
    marginBottom: 30,
    '&:after': {
      content: '""',
      width: '2px',
      height: '48px',
      backgroundColor: '#6C59B4',
      position: 'absolute',
      left: '0',
      top: '0',
    },
    '& .MuiTypography-subtitle1': {
      fontWeight: 600,
      marginBottom: '10px !important',
    },
    '& .MuiTypography-subtitle2': {
      fontWeight: 'bold',
      marginBottom: '10px !important',
    },
    '& .MuiTypography-root': {
      marginBottom: 20,
      '& a': {
        color: '#6C59B4',
        textDecoration: 'none',
      },
    },
    '& ul': {
      margin: 0,
      paddingLeft: 0,
      '& li': {
        fontSize: 14,
        listStyleType: 'none',
        paddingBottom: 20,
        '& >span': {
          paddingLeft: 28,
          paddingRight: 12,
        },
      },
    },
    '& ul .MuiTypography-subtitle1 span': {
      paddingLeft: 0,
    },
  },
  bannerBg: {
    backgroundImage: 'url(/static/images/banner.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    backgroundPositionY: '-50px',
    minHeight: '508px',
  },
  pageBg: {
    backgroundImage: 'url(/static/images/page-bg.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    backgroundPositionY: '-50px',
  },
  pageTitle: {
    fontSize: '18px',
    fontWeight: 600,
    margin: '0 0 20px 0',
  },
  scrollBox: {
    maxHeight: '500px',
    position: 'relative',
    paddingRight: theme.spacing(1.5),
  },
  table: {
    marginBottom: 30,
    width: '75%',
    '& .MuiTableCell-head': {
      fontWeight: 'bold',
    },
    '& .MuiTableCell-root': {
      border: '1px solid rgba(224, 224, 224, 1)',
      verticalAlign: 'top',
      padding: '16px !important',
      width: '50%',
    },
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    padding: '0 40px',
  },
  inlineDiv: {
    display: 'inline-flex',
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
  button: {
    backgroundColor: '#6C59B4',
    padding: theme.spacing(1, 2.5),
    borderRadius: '20px',
    color: '#fff',
    textTransform: 'inherit',
    border: 0,
    outline: 'none',
    cursor: 'pointer',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: ' #493E75',
    },
  },
  subtitle3: {
    fontWeight: 'bold',
    marginBottom: '10px !important',
    fontSize: '1rem',
    margin: '0 auto',
    textAlign: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  padTop: {
    paddingTop: 15,
  },
  noPad: {
    padding: '0px !important',
  },
  padRight: {
    paddingRight: 12,
    paddingLeft: '0px !important',
  },
  underLine: {
    textDecorationLine: 'underline',
  },
}));

function AboutUs() {
  const classes = useStyles();

  return (
    <Container fixed>
      <Box className={`${classes.bannerBg} ${classes.pageBg}`}>
        <Header />
        <Box className={classes.mainWrapper}>
          <Typography variant="subtitle1">About Us</Typography>
          <Typography>
            After 40 years in aviation, mostly rotor wing, Darl Evans retired as
            Chief Pilot for Blue Hawaiian Helicopters. Having a few years to
            reflect on all the amazing things he was fortunate to have seen and
            experienced, Darl felt a compelling need to start Ident and give
            back to the industry and the people that make it so unique and
            versatile.
          </Typography>
          <Typography>
            The one issue Darl experienced throughout his career was related to
            career progression, specifically the seemingly hap-hazard way it
            develops for most folks on their way up. Also, he has experienced
            the lack of any real structured process, aside from continuing to
            work a regular job, in which the vast knowledge and experience of
            industry seniors can be passed down and put to good use for the
            benefit of all.
          </Typography>
          <Typography>
            IDENT has been created to address those issues by licensing from
            Placement Loop, a digital platform upon which IDENT is based. The
            entire process is based on placing personnel (a company's “human
            capital”) at the “RIGHT FIT” thus improving employees’ career
            well-being and increasing their employer’s business performance.
          </Typography>
          <Typography>
            All this is accomplished by a network of industry professionals
            “Navigators” who monetize their knowledge as independent contractors
            working to place people at the Right Fit throughout their careers.
          </Typography>
          <Typography>
            Learn more about us and join the movement to improve the helicopter
            industry experience for all involved.
          </Typography>
          <div style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              style={{ marginLeft: '0' }}
              onClick={() => window.open(`${window.location.origin}/MovementPage`, '_blank')}
            >
              Join The Movement
            </Button>
          </div>
        </Box>
      </Box>
    </Container>
  );
}

export default AboutUs;
