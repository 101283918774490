import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Logo from './Logo';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(3.5, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '1150px',
    margin: '0 auto',
  },
  logo: {
    width: '230px',
  },
  toolbarWrapper: {
    '&.MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  navLink: {
    margin: theme.spacing(0, 1.5),
    color: '#fff',
  },
  dark: {
    '& .MuiLink-root': {
      margin: theme.spacing(0, 1.5),
      color: '#5E5B70',
    },
  },
  light: {
    '& .MuiLink-root': {
      margin: theme.spacing(0, 1.5),
      color: '#fff',
    },
  },
  navLinkDark: {
    margin: theme.spacing(0, 1.5),
    color: '#5E5B70',
  },
  btnLogin: {
    backgroundColor: '#6C59B4',
    padding: theme.spacing(0.5, 2),
    borderRadius: '20px',
    color: '#fff !important',
  },
  nomargin: {
    margin: '0 !important',
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div
      className={`${classes.header} ${
        window.location.pathname == '/home' ? classes.light : classes.dark
      }`}
    >
      <Link className={classes.nomargin} component={RouterLink} to="/">
        <Logo className={`${classes.logo}`} />
      </Link>
      <Toolbar
        className={`${classes.toolbarWrapper} ${
          window.location.pathname == '/home' ? classes.light : classes.dark
        }`}
      >
        <Link
          className={classes.navLink}
          component={RouterLink}
          to="/AboutUs"
          target="blank"
        >
          {' '}
          {!history?.location?.pathname.includes('AboutUs') && 'About Us'}
        </Link>
        <Link
          className={classes.navLink}
          component={RouterLink}
          to="/ContactUs"
          target="_blank"
        >
          {' '}
          {!history?.location?.pathname.includes('ContactUs') && 'Contact Us'}
        </Link>
        {/* <Link
          className={classes.navLink}
          component={RouterLink}
          to="/"
          >
          {' '}
          Privacy Policy
        </Link> */}
        <Link
          className={`${classes.btnLogin} ${classes.nomargin}`}
          component={RouterLink}
          to="/login"
        >
          {' '}
          Sign in
        </Link>
      </Toolbar>
    </div>
  );
}
