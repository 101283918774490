import { userRoles } from '../../utils';
import request from './request';
import requestToken from './requestToken';
import requestTokenPDF from './requestTokenPDF';

export const getMembership = () => requestToken({
  url: 'users/memberships',
  method: 'GET',
});

export const getContractPdf = (name) => requestTokenPDF({
  url: `/contracts/orders/buy/file-download-link?fileName=${name}`,
  method: 'GET',
});

export const agreeContract = (data) => requestToken({
  url: 'contracts/orders/buy/agreement',
  method: 'PUT',
  data,
});

export const contactTokenVerify = (token) => request({
  url: `/contracts/orders/buy/verify-contract-link?contractToken=${token}`,
  method: 'GET',
});

export const getProfile = (id) => requestToken({
  url: `/users/profile${id ? `?userId=${id}` : ''}`,
  method: 'GET',
});

export const verifyRegistration = (email) => request({
  url: `/auth/verify-registration?email=${email}`,
  method: 'GET',
});

export const getOnboardingProfile = (data, query = '') => requestToken({
  url: `/payments/stripe/create-account-link${query}`,
  method: 'POST',
  data,
});

export const verifyRegistrationWithToken = (token) => request({
  url: `auth/verify-registration${token}`,
  method: 'GET',
});

export const userRegistration = (data) => request({
  url: 'auth/register',
  method: 'POST',
  data,
});

export const getUserProfileDetails = (userId, workersListFlag) => requestToken({
  url: `users/profile${
    userId ? `?${workersListFlag ? 'pdaDomain' : 'userId'}=${userId}` : ''
  }`,
  method: 'GET',
});

export const editUserProfile = (data) => requestToken({
  url: 'users/profile',
  method: 'PUT',
  data,
});

export const inviteUser = (data) => requestToken({
  url: 'auth/invite',
  method: 'POST',
  data,
});

export const verifyPasswordLink = (path) => request({
  url: `auth/verify-change-password-link${path}`,
  method: 'GET',
});

export const getNotification = (query) => requestToken({
  url: `users/notifications?${query}${
    localStorage.getItem('loopAdminAsCN') === 'true'
      && localStorage.getItem('userRole') === userRoles.LOOP_ADMIN
      ? `&loopAdminAsCN=${true}`
      : ''
  }`,
  method: 'GET',
});

export const markNotification = (query, data) => requestToken({
  url: `users/notifications/statuses/read-update?${query}`,
  method: 'POST',
  data,
});

export const changePassword = (data) => request({
  url: 'auth/change-password',
  method: 'PUT',
  data,
});

export const forgotPasswordConfirm = (data) => request({
  url: 'auth/forgot-password',
  method: 'POST',
  data,
});
export const fetchLoopList = () => requestToken({
  url: 'loops',
  method: 'GET',
});

export const verifyRegistrationLocation = (location) => request({
  url: `auth/verify-registration${location}`,
  method: 'GET',
});

export const generateInviteLink = (data) => requestToken({
  url: 'auth/referral',
  method: 'POST',
  data,
});

export const assignCarNav = (data) => requestToken({
  url: 'orders/buy/reassign',
  method: 'POST',
  data,
});

export const assignCarNavPO = (data) => requestToken({
  url: 'orders/sell/reassign',
  method: 'POST',
  data,
});

export const getDashboardData = (loopId, orgId) => requestToken({
  url: `users/dashboard?loopId=${loopId}&orgId=${orgId}${
    localStorage.getItem('loopAdminAsCN') === 'true'
      && localStorage.getItem('userRole') === userRoles.LOOP_ADMIN
      ? `&loopAdminAsCN=${true}`
      : ''
  }`,
  method: 'GET',
});

export const SubmitOrder = (data) => requestToken({
  url: `orders/buy?${
    localStorage.getItem('userRole')
      ? `loopId=${localStorage.getItem(
        'loopId',
      )}&orgId=${localStorage.getItem('orgId')}`
      : 'loopCode=HELICOPTER_PILOT&orgCode=IDENT'
  }`,
  method: 'POST',
  data,
});
export const SubmitWorkerOrder = (data) => requestToken({
  url: 'orders/buy',
  method: 'PATCH',
  data,
});
