import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Header from '../../component/Header/Header';
import { userRoles } from '../../utils';
import { config } from '../../config/config';

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    backgroundColor: '#fff',
    borderRadius: '0 10px 10px 10px',
    boxShadow: '0 3px 6px rgba(0,0,0,.16)',
    padding: theme.spacing(3.5),
    position: 'relative',
    width: '1150px',
    margin: '0 auto',
    marginBottom: 30,
    '&:after': {
      content: '""',
      width: '2px',
      height: '48px',
      backgroundColor: '#6C59B4',
      position: 'absolute',
      left: '0',
      top: '0',
    },
    '& .MuiTypography-subtitle1': {
      fontWeight: 600,
      marginBottom: '10px !important',
    },
    '& .MuiTypography-subtitle2': {
      fontWeight: 'bold',
      marginBottom: '10px !important',
    },
    '& .MuiTypography-root': {
      marginBottom: 20,
      '& a': {
        color: '#6C59B4',
        textDecoration: 'none',
      },
    },
    '& li': {
      fontSize: 14,
    },
  },
  bannerBg: {
    backgroundImage: 'url(/static/images/banner.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    backgroundPositionY: '-50px',
    minHeight: '508px',
  },
  pageBg: {
    backgroundImage: 'url(/static/images/page-bg.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    backgroundPositionY: '-50px',
  },
  pageTitle: {
    fontSize: '18px',
    fontWeight: 600,
    margin: '0 0 20px 0',
  },
  scrollBox: {
    maxHeight: '500px',
    position: 'relative',
    paddingRight: theme.spacing(1.5),
  },
  table: {
    marginBottom: 30,
    width: '75%',
    '& .MuiTableCell-head': {
      fontWeight: 'bold',
    },
    '& .MuiTableCell-root': {
      border: '1px solid rgba(224, 224, 224, 1)',
      verticalAlign: 'top',
      padding: '16px !important',
      width: '50%',
      '& ul': {
        margin: 0,
        paddingLeft: 20,
      },
    },
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    padding: '0 40px',
  },
  inlineDiv: {
    display: 'inline-flex',
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
  button: {
    backgroundColor: '#6C59B4',
    padding: theme.spacing(1, 2.5),
    borderRadius: '20px',
    color: '#fff',
    textTransform: 'inherit',
    border: 0,
    outline: 'none',
    cursor: 'pointer',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: ' #493E75',
    },
  },
}));

function TermsOfUse() {
  const classes = useStyles();
  const [state, setStates] = React.useState(false);
  const handleChangeCheckbox = (event) => {
    state ? setStates(false) : setStates(true);
  };

  return (
    <Container fixed>
      <Box className={`${classes.bannerBg} ${classes.pageBg}`}>
        <Header />

        <Box className={classes.mainWrapper}>
          <Typography>Last updated April 7, 2021</Typography>
          <Typography>
            <u> Agreement between user and Ident LLC</u>
          </Typography>
          <Typography>
            The
            {' '}
            <a heref=" https://ident.placementloop.com">
              https://ident.placementloop.com
            </a>
            {' '}
            website (the "Site") consists of various web pages operated by Ident
            LLC ("Ident").
            {' '}
            <a href="https://ident.placementloop.com">
              {' '}
              https://ident.placementloop.com
              {' '}
            </a>
            {' '}
            is offered to you conditioned on your acceptance without
            modification of the terms, conditions, and notices contained herein
            (the "Terms"). Your use of
            {' '}
            <a href="https://ident.placementloop.com">
              https://ident.placementloop.com
            </a>
            {' '}
            constitutes your agreement to all such Terms. Please read these
            terms carefully, and keep a copy of them for your reference.
          </Typography>
          <Typography>
            <a href=" https://ident.placementloop.com">
              https://ident.placementloop.com
            </a>
            {' '}
            is an E-commerce Site Service for sale.
          </Typography>
          <Typography variant="subtitle1">
            <u> Privacy</u>
          </Typography>
          <Typography>
            Your use of
            {' '}
            <a href="https://https://ident.placementloop.com">
              https://https://ident.placementloop.com
            </a>
            {' '}
            is subject to Ident's Privacy Policy. Please review our Privacy
            Policy located at
            {' '}
            <a href="https://ident.placementloop.com/privacyPolicy">
              {' '}
              https://ident.placementloop.com/privacyPolicy
              {' '}
            </a>
            {' '}
            which also governs the Site and informs users of our data collection
            practices.
          </Typography>
          <Typography variant="subtitle1">
            <u> Electronic Communications</u>
          </Typography>
          <Typography>
            Visiting
            {' '}
            <a href="https://ident.placementloop.com">
              {' '}
              https://ident.placementloop.com
              {' '}
            </a>
            {' '}
            or sending emails to Ident constitutes electronic communications.
            You consent to receive electronic communications and you agree that
            all agreements, notices, disclosures and other communications that
            we provide to you electronically, via email and on the Site, satisfy
            any legal requirement that such communications be in writing.
          </Typography>
          <Typography>
            Ident does not knowingly collect, either online or offline, personal
            information from persons under the age of thirteen. If you are under
            18, you may use
            {' '}
            <a href="https://ident.placementloop.com">
              https://ident.placementloop.com
            </a>
            {' '}
            only with permission of a parent or guardian.
          </Typography>
          <Typography variant="subtitle1">
            <u> Links to third party sites/Third party services</u>
          </Typography>
          <Typography>
            <a href="https://ident.placementloop.com">
              https://ident.placementloop.com
            </a>
            {' '}
            may contain links to other websites ("Linked Sites"). The Linked
            Sites are not under the control of Ident and Ident is not
            responsible for the contents of any Linked Site, including without
            limitation any link contained in a Linked Site, or any changes or
            updates to a Linked Site. Ident is providing these links to you only
            as a convenience, and the inclusion of any link does not imply
            endorsement by Ident of the site or any association with its
            operators.
          </Typography>
          <Typography>
            Certain services made available via
            {' '}
            <a href="https://ident.placementloop.com">
              https://ident.placementloop.com
            </a>
            {' '}
            are delivered by third party sites and organizations. By using any
            product, service or functionality originating from the
            {' '}
            <a href="https://ident.placementloop.com">
              https://ident.placementloop.com
            </a>
            {' '}
            domain, you hereby acknowledge and consent that Ident may share such
            information and data with any third party with whom Ident has a
            contractual relationship to provide the requested product, service
            or functionality on behalf of
            {' '}
            <a href="https://ident.placementloop.com">
              {' '}
              https://ident.placementloop.com
            </a>
            {' '}
            users and customers.
          </Typography>
          <Typography variant="subtitle1">
            <u> No unlawful or prohibited use/Intellectual Property</u>
          </Typography>
          <Typography>
            You are granted a non-exclusive, non-transferable, revocable license
            to access and use
            {' '}
            <a href="https://ident.placementloop.com">
              https://ident.placementloop.com
            </a>
            {' '}
            strictly in accordance with these terms of use. As a condition of
            your use of the Site, you warrant to Ident that you will not use the
            Site for any purpose that is unlawful or prohibited by these Terms.
            You may not use the Site in any manner which could damage, disable,
            overburden, or impair the Site or interfere with any other party's
            use and enjoyment of the Site. You may not obtain or attempt to
            obtain any materials or information through any means not
            intentionally made available or provided for through the Site.
          </Typography>
          <Typography>
            All content included as part of the Service, such as text, graphics,
            logos, images, as well as the compilation thereof, and any software
            used on the Site, is the property of Ident or its suppliers and
            protected by copyright and other laws that protect intellectual
            property and proprietary rights. You agree to observe and abide by
            all copyright and other proprietary notices, legends or other
            restrictions contained in any such content and will not make any
            changes thereto.
          </Typography>
          <Typography>
            You will not modify, publish, transmit, reverse engineer,
            participate in the transfer or sale, create derivative works, or in
            any way exploit any of the content, in whole or in part, found on
            the Site. Ident content is not for resale. Your use of the Site does
            not entitle you to make any unauthorized use of any protected
            content, and in particular you will not delete or alter any
            proprietary rights or attribution notices in any content. You will
            use protected content solely for your personal use, and will make no
            other use of the content without the express written permission of
            Ident and the copyright owner. You agree that you do not acquire any
            ownership rights in any protected content. We do not grant you any
            licenses, express or implied, to the intellectual property of Ident
            or our licensors except as expressly authorized by these Terms.
          </Typography>
          <Typography variant="subtitle1">
            <u> Third Party Accounts</u>
          </Typography>
          <Typography>
            You will be able to connect your Ident account to third party
            accounts. By connecting your Ident account to your third party
            account, you acknowledge and agree that you are consenting to the
            continuous release of information about you to others (in accordance
            with your privacy settings on those third party sites). If you do
            not want information about you to be shared in this manner, do not
            use this feature.
          </Typography>
          <Typography variant="subtitle1">
            <u> International Users</u>
          </Typography>
          <Typography>
            The Service is controlled, operated and administered by Ident from
            our offices within the USA. If you access the Service from a
            location outside the USA, you are responsible for compliance with
            all local laws. You agree that you will not use the Ident Content
            accessed through
            {' '}
            <a href="https://ident.placementloop.com">
              https://ident.placementloop.com
            </a>
            {' '}
            in any country or in any manner prohibited by any applicable laws,
            restrictions or regulations.
          </Typography>
          <Typography variant="subtitle1">
            <u>Indemnification</u>
          </Typography>
          <Typography>
            You agree to indemnify, defend and hold harmless Ident, its
            officers, directors, employees, agents and third parties, for any
            losses, costs, liabilities and expenses (including reasonable
            attorneys' fees) relating to or arising out of your use of or
            inability to use the Site or services, any user postings made by
            you, your violation of any terms of this Agreement or your violation
            of any rights of a third party, or your violation of any applicable
            laws, rules or regulations. Ident reserves the right, at its own
            cost, to assume the exclusive defense and control of any matter
            otherwise subject to indemnification by you, in which event you will
            fully cooperate with Ident in asserting any available defenses.
          </Typography>
          <Typography variant="subtitle1">
            <u>Liability disclaimer</u>
          </Typography>
          <Typography>
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
            AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
            ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.
            IDENT AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN
            THE SITE AT ANY TIME.
          </Typography>
          <Typography>
            IDENT AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
            SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF
            THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
            CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE,
            PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT
            WARRANTY OR CONDITION OF ANY KIND. IDENT AND/OR ITS SUPPLIERS HEREBY
            DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
            INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS,
            INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
          </Typography>
          <Typography>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            IDENT AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT,
            PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
            WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE,
            DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE
            OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE
            SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE
            SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
            RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT
            OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE,
            STRICT LIABILITY OR OTHERWISE, EVEN IF IDENT OR ANY OF ITS SUPPLIERS
            HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
            STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
            LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
            LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY
            PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE
            AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
          </Typography>
          <Typography variant="subtitle1">
            <u>Termination/access restriction</u>
          </Typography>
          <Typography>
            Ident reserves the right, in its sole discretion, to terminate your
            access to the Site and the related services or any portion thereof
            at any time, without notice. To the maximum extent permitted by law,
            this agreement is governed by the laws of the State of North
            Carolina and you hereby consent to the exclusive jurisdiction and
            venue of courts in North Carolina in all disputes arising out of or
            relating to the use of the Site. Use of the Site is unauthorized in
            any jurisdiction that does not give effect to all provisions of
            these Terms, including, without limitation, this section.
          </Typography>
          <Typography>
            You agree that no joint venture, partnership, employment, or agency
            relationship exists between you and Ident as a result of this
            agreement or use of the Site. Ident's performance of this agreement
            is subject to existing laws and legal process, and nothing contained
            in this agreement is in derogation of Ident's right to comply with
            governmental, court and law enforcement requests or requirements
            relating to your use of the Site or information provided to or
            gathered by Ident with respect to such use. If any part of this
            agreement is determined to be invalid or unenforceable pursuant to
            applicable law including, but not limited to, the warranty
            disclaimers and liability limitations set forth above, then the
            invalid or unenforceable provision will be deemed superseded by a
            valid, enforceable provision that most closely matches the intent of
            the original provision and the remainder of the agreement shall
            continue in effect.
          </Typography>
          <Typography>
            Unless otherwise specified herein, this agreement constitutes the
            entire agreement between the user and Ident with respect to the Site
            and it supersedes all prior or contemporaneous communications and
            proposals, whether electronic, oral or written, between the user and
            Ident with respect to the Site. A printed version of this agreement
            and of any notice given in electronic form shall be admissible in
            judicial or administrative proceedings based upon or relating to
            this agreement to the same extent and subject to the same conditions
            as other business documents and records originally generated and
            maintained in printed form. It is the express wish to the parties
            that this agreement and all related documents be written in English.
          </Typography>
          <Typography variant="subtitle1">
            <u>Changes to Terms</u>
          </Typography>
          <Typography>
            Ident welcomes your questions or comments regarding the Terms:
          </Typography>
          <Typography>
            Ident
            <br />
            235 E Palmer Drive
            <br />
            New Bern, NC 28560
          </Typography>
          <Typography>
            Email Address:
            <br />
            support@career-ident.com
            <br />
          </Typography>
          <Typography>Effective as of April 7, 2021</Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default TermsOfUse;
