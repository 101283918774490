import { config } from '../../config/config';

const userRoles = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  LICENSE_OWNER: 'LICENSE_OWNER',
  LOOP_ADMIN: 'LOOP_ADMIN',
  CAREER_NAVIGATOR: 'CAREER_NAVIGATOR',
  BUYER: 'BUYER',
  SELLER: 'SELLER',
  RDIRECT_URL: config.redirect_url,
};
export default userRoles;
