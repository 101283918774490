import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Header from '../../component/Header/Header';
import 'react-perfect-scrollbar/dist/css/styles.css';

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    backgroundColor: '#fff',
    borderRadius: '0 10px 10px 10px',
    boxShadow: '0 3px 6px rgba(0,0,0,.16)',
    padding: theme.spacing(3.5),
    position: 'relative',
    width: '1150px',
    margin: '0 auto',
    marginBottom: 30,
    '&:after': {
      content: '""',
      width: '2px',
      height: '48px',
      backgroundColor: '#6C59B4',
      position: 'absolute',
      left: '0',
      top: '0',
    },
    '& .MuiTypography-subtitle1': {
      fontWeight: 600,
      marginBottom: '10px !important',
    },
    '& .MuiTypography-subtitle2': {
      fontWeight: 'bold',
      marginBottom: '10px !important',
    },
    '& .MuiTypography-root': {
      marginBottom: 20,
      '& a': {
        color: '#6C59B4',
        textDecoration: 'none',
      },
    },
    '& ul': {
      margin: 0,
      paddingLeft: 0,
      '& li': {
        fontSize: 14,
        listStyleType: 'none',
        paddingBottom: 20,
        '& >span': {
          paddingLeft: 28,
          paddingRight: 12,
        },
      },
    },
    '& ul .MuiTypography-subtitle1 span': {
      paddingLeft: 0,
    },
  },
  bannerBg: {
    backgroundImage: 'url(/static/images/banner.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    backgroundPositionY: '-50px',
    minHeight: '508px',
  },
  pageBg: {
    backgroundImage: 'url(/static/images/page-bg.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    backgroundPositionY: '-50px',
  },
  pageTitle: {
    fontSize: '18px',
    fontWeight: 600,
    margin: '0 0 20px 0',
  },
  scrollBox: {
    maxHeight: '500px',
    position: 'relative',
    paddingRight: theme.spacing(1.5),
  },
  table: {
    marginBottom: 30,
    width: '75%',
    '& .MuiTableCell-head': {
      fontWeight: 'bold',
    },
    '& .MuiTableCell-root': {
      border: '1px solid rgba(224, 224, 224, 1)',
      verticalAlign: 'top',
      padding: '16px !important',
      width: '50%',
    },
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    padding: '0 40px',
  },
  inlineDiv: {
    display: 'inline-flex',
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
  button: {
    backgroundColor: '#6C59B4',
    padding: theme.spacing(1, 2.5),
    borderRadius: '20px',
    color: '#fff',
    textTransform: 'inherit',
    border: 0,
    outline: 'none',
    cursor: 'pointer',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: ' #493E75',
    },
  },
  subtitle3: {
    fontWeight: 'bold',
    marginBottom: '10px !important',
    fontSize: '1rem',
    margin: '0 auto',
    textAlign: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  padTop: {
    paddingTop: 15,
  },
  noPad: {
    padding: '0px !important',
  },
  padRight: {
    paddingRight: 12,
    paddingLeft: '0px !important',
  },
  underLine: {
    textDecorationLine: 'underline',
  },
}));

function ContactUs() {
  const classes = useStyles();

  return (
    <Container fixed>
      <Box className={`${classes.bannerBg} ${classes.pageBg}`}>
        <Header />
        <Box className={classes.mainWrapper}>
          <Typography variant="subtitle1">
            <u>Contact</u>
          </Typography>
          <Typography>
            Darl Evans
            <br />
            Ident
            <br />
            darl.evans@career-ident.com
            <br />
            808.936.6889
            <br />
            New Bern, NC 28560
            <br />
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default ContactUs;
