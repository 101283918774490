import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  makeStyles,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import {
  userRegistration,
  verifyRegistrationWithToken,
} from '../../redux/services/users';
import AppSpinner from '../../component/AppSpinner';
import { ToasterNotification } from '../../utils';

const useStyles = makeStyles((theme) => ({
  textMargin: {
    marginBottom: theme.spacing(1.25),
  },
  error: {
    color: '#ff0000',
  },
}));

function VerifyIO() {
  const classes = useStyles();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [open, setOpen] = useState(false);
  const [showAppSpinner, setShowAppSpinner] = React.useState(false);
  const [error, setError] = useState('');
  const [toastOpen, setToastOpen] = React.useState({
    open: false,
    message: '',
    type: '',
  });
  const history = useHistory();

  useEffect(() => {
    if (history.location?.search?.includes('influentialOtherToken=')) {
      verifyToken();
    }
  }, []);

  const verifyToken = async () => {
    try {
      setShowAppSpinner(true);
      const res = await verifyRegistrationWithToken(history?.location?.search);
      if (res?.statusCode === 200) {
        if (res?.data?.isAccountVerified) {
          history.push(
            `/Io/OrderDetails/${history?.location?.search?.split('=')[1]}`,
          );
        } else {
          setOpen(true);
        }
        setShowAppSpinner(false);
      } else {
        setToastOpen({ open: true, message: res?.message, type: 'error' });
        setShowAppSpinner(false);
      }
    } catch (err) {
      console.error('Err in verify token => ', err);
      setToastOpen({ open: true, message: err?.message, type: 'error' });
      setShowAppSpinner(false);
    }
  };

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    setError('');
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
    setError('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const letter = /[^0-9a-zA-Z]+$/;
    if (firstName == '') {
      setError('Please enter first Name');
    } else if (lastName == '') {
      setError('Please enter last Name');
    } else if (firstName.replace(/\s/, '').length <= 0) {
      setError('Please enter First Name');
    } else if (lastName.replace(/\s/, '').length <= 0) {
      setError('Please enter Last Name');
    } else if ((firstName && lastName).match(letter)) {
      setError('Remove White Spaces');
    } else {
      const data = {
        firstName,
        lastName,
        influentialOtherToken: history?.location?.search?.split('=')[1],
      };
      registerUser(data);
    }
  };

  const registerUser = async (data) => {
    try {
      setShowAppSpinner(true);
      const res = await userRegistration(data);
      if (res?.statusCode === 200) {
        setShowAppSpinner(false);
        history.push(
          `/Io/OrderDetails/${history?.location?.search?.split('=')[1]}`,
        );
      } else {
        setShowAppSpinner(false);
      }
    } catch (err) {
      console.error('Err in user registration => ', err);
      setShowAppSpinner(false);
    }
  };

  const handleCloseToast = () => {
    setToastOpen({ open: false, message: '', type: '' });
  };

  return (
    <div>
      {showAppSpinner && <AppSpinner />}
      <Dialog open={open} fullWidth maxWidth="xs">
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                className={classes.textMargin}
                value={firstName}
                label="First Name"
                onChange={handleFirstName}
              />
              <TextField
                className={classes.textMargin}
                value={lastName}
                label="Last Name"
                onChange={handleLastName}
              />
            </FormControl>
            <span className={classes.error}>{error}</span>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={(firstName && lastName).length < 1}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {toastOpen.open && (
        <ToasterNotification
          open={toastOpen.open}
          message={toastOpen.message}
          type={toastOpen.type}
          handleClose={handleCloseToast}
        />
      )}
    </div>
  );
}

export default VerifyIO;
